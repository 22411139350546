import {
  FormControl,
  VStack,
  Box,
  Checkbox
} from '@chakra-ui/react'

export const CertificationFormSection = ({register, errors}: {register: any, errors: any}) => {
  return (
    <VStack align="left" spacing="6px" marginTop="20px">
      <FormControl isInvalid={errors.priority !== undefined} isRequired>
        <Checkbox {...register('registrability', {
          required: true,
        })}>I understand this trademark application will be filed without a registrability or availability analysis.<Box marginLeft="4px" as="span" color="red.500">*</Box></Checkbox>
      </FormControl>
    </VStack>
  )
}
