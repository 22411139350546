import {
  Text,
  VStack,
  Box
} from '@chakra-ui/react'

export const ContactPersonSummary = ({ name, email}: { name: string, email: string}) => {
  return (
    <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
      <Box>
        <Text fontWeight="bold">Name</Text>
        <Text>{name}</Text>
      </Box>
      <Box>
        <Text fontWeight="bold">Email</Text>
        <Text>{email}</Text>
      </Box>
    </VStack>
  )
}
