import {
  Text,
  VStack,
  Box
} from '@chakra-ui/react'

export const ApplicantSummary = ({draftTrademarkInfo}: {draftTrademarkInfo: any}) => {
  const type = draftTrademarkInfo.applicantType;

  let name = '';
  if (type === 'Individual') {
    name = draftTrademarkInfo.applicantFirstName + ' ' + draftTrademarkInfo.applicantLastName;
  } else if (type === 'Organization') {
    name = draftTrademarkInfo.applicantCorporationName;
  }

  const email = draftTrademarkInfo.applicantEmail;
  const streetAddress = draftTrademarkInfo.applicantStreetAddress;
  const city = draftTrademarkInfo.applicantCity;
  const state = draftTrademarkInfo.applicantState;
  const country = draftTrademarkInfo.applicantCountry;
  const postalCode = draftTrademarkInfo.applicantPostalCode;

  return (
    <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
      <Box>
        <Text fontWeight="bold">Applicant Type</Text>
        <Text>{type}</Text>
      </Box>
      <Box>
        <Text fontWeight="bold">Name</Text>
        <Text>{name}</Text>
      </Box>
      <Box>
        <Text fontWeight="bold">Email</Text>
        <Text>{email}</Text>
      </Box>
      <Box>
        <Text fontWeight="bold">Address</Text>
        <Text>{`${streetAddress}, ${city},${state ? ` ${state}` : ""} ${postalCode}, ${country}`}</Text>
      </Box>
    </VStack>
  )
}
