import {
  Text,
  VStack,
  Box
} from '@chakra-ui/react'

export const InternationalFilingsReview = ({priority, priorityDetails}: {priority: boolean, priorityDetails: string | null}) => {
  return (
    <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
      <Box>
        <Text fontWeight="bold">Convention priority application?</Text>
        <Text>{priority ? "Yes" : "No"}</Text>
      </Box>
      {
        priority && 
        <Box>
          <Text fontWeight="bold">Priority Details</Text>
          <Text whiteSpace="pre-wrap">{priorityDetails}</Text>
        </Box>
      }
    </VStack>
  )
}
