import {
  FormErrorMessage,
  FormControl,
  Box,
  Textarea
} from '@chakra-ui/react'

export const AdditionalCommentsSection = ({register, errors}: {register: any, errors: any}) => {
  return (
    <Box>
      <FormControl isInvalid={errors.additionalComments !== undefined}>
        <Textarea 
          id='additionalComments'
          placeholder='I want to note...'
          {...register('additionalComments')}
        />
        <FormErrorMessage>
          {errors.additionalComments?.message?.toString()}
        </FormErrorMessage>
      </FormControl>
    </Box>
  )
}
