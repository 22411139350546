import {
  Text,
  VStack
} from '@chakra-ui/react'

export const AdditionalCommentsReview = ({comments}: {comments: string | null}) => {
  return (
    <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
      <Text whiteSpace="pre-wrap">{comments ?? 'None Provided'}</Text>
    </VStack>
  )
}
