import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  VStack,
  Box,
  HStack
} from '@chakra-ui/react'
import { CountryInput } from './CountryInput'
import { StateInput } from "./StateInput"

export const RequestingFirmFormSection = ({register, errors, setValue, watch}: {register: any, errors: any, setValue: any, watch: any}) => {
  return (
    <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
      <Box>
        <FormControl isInvalid={errors.requestingFirmName !== undefined} isRequired>
          <FormLabel htmlFor='requestingFirmName'>Firm Name</FormLabel>
          <Input
            id='requestingFirmName'
            placeholder='Example LLP'
            {...register('requestingFirmName', {
              required: true,
            })}
          />
          <FormErrorMessage>
            {errors.requestingFirmName?.message?.toString()}
          </FormErrorMessage>
        </FormControl>
      </Box>
      <Box>
        <FormControl isInvalid={errors.requestingFirmStreetAddress !== undefined} isRequired>
          <FormLabel htmlFor='requestingFirmStreetAddress'>Street Address</FormLabel>
          <Input
            id='requestingFirmStreetAddress'
            placeholder='210 Lexington Ave, Suite 570'
            {...register('requestingFirmStreetAddress', {
              required: true,
            })}
          />
          <FormErrorMessage>
            {errors.requestingFirmStreetAddress?.message?.toString()}
          </FormErrorMessage>
        </FormControl>
      </Box>
      <HStack>
        <Box>
          <FormControl isInvalid={errors.requestingFirmCity !== undefined} isRequired>
            <FormLabel htmlFor='requestingFirmCity'>City</FormLabel>
            <Input
              id='requestingFirmCity'
              placeholder='New York'
              {...register('requestingFirmCity', {
                required: true,
              })}
            />
            <FormErrorMessage>
              {errors.requestingFirmCity?.message?.toString()}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <StateInput id="requestingFirmState" countryFormId={"requestingFirmCountry"} register={register} errors={errors} setValue={setValue} watch={watch} />
        <CountryInput id="requestingFirmCountry" register={register} errors={errors} />
      </HStack>
      <Box>
        <FormControl isInvalid={errors.requestingFirmPostalCode !== undefined} isRequired>
          <FormLabel htmlFor='requestingFirmPostalCode'>ZIP/Postal Code</FormLabel>
          <Input
            id='requestingFirmPostalCode'
            placeholder='10065'
            {...register('requestingFirmPostalCode', {
              required: true,
            })}
          />
          <FormErrorMessage>
            {errors.requestingFirmPostalCode?.message?.toString()}
          </FormErrorMessage>
        </FormControl>
      </Box>
    </VStack>
  )
}
