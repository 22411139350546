import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  VStack,
  Box,
} from '@chakra-ui/react'

export const ContactPersonFormSection = ({register, errors}: {register: any, errors: any}) => {
  return (
    <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
      <Box>
        <FormControl isInvalid={errors.contactPersonName !== undefined} isRequired>
          <FormLabel htmlFor='contactPersonName'>Full Name</FormLabel>
          <Input
            id='contactPersonName'
            placeholder='Brian Jones'
            {...register('contactPersonName', {
              required: true,
            })}
          />
          <FormErrorMessage>
            {errors.contactPersonName?.message?.toString()}
          </FormErrorMessage>
        </FormControl>
      </Box>
      <Box>
        <FormControl isInvalid={errors.contactPersonEmail !== undefined} isRequired>
          <FormLabel htmlFor='contactPersonEmail'>Email</FormLabel>
          <Input
            id='contactPersonEmail'
            type='email'
            placeholder='brian.jones@example-llp.com'
            {...register('contactPersonEmail', {
              required: true,
            })}
          />
          <FormErrorMessage>
            {errors.contactPersonEmail?.message?.toString()}
          </FormErrorMessage>
        </FormControl>
      </Box>
    </VStack>
  )
}
