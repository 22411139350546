import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  VStack,
  Box,
  HStack,
  RadioGroup,
  Radio
} from '@chakra-ui/react'
import { CountryInput } from './CountryInput'
import { StateInput } from './StateInput'

export const ApplicantFormSection = ({register, errors, setValue, watch, previouslySelectedType}: {register: any, errors: any, setValue: any, watch: any, previouslySelectedType: string | null}) => {
  const applicantType = watch('applicantType')
  return (
    <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
      <Box>
        <FormLabel htmlFor='applicantType'>Applicant Type</FormLabel>
        <RadioGroup defaultValue={previouslySelectedType ?? 'Individual'}>
          <VStack align="left">
            <Radio value='Individual' {...register('applicantType')}>Individual</Radio>
            <Radio value='Organization' {...register('applicantType')}>Organization</Radio>
          </VStack>
        </RadioGroup>
      </Box>
      {
        applicantType === 'Individual' && 
        <HStack width="full">
          <Box width="50%">
            <FormControl isInvalid={errors.applicantFirstName !== undefined} isRequired>
              <FormLabel htmlFor='applicantFirstName'>First Name</FormLabel>
              <Input
                id='applicantFirstName'
                placeholder='John'
                {...register('applicantFirstName', {
                  required: true,
                })}
              />
              <FormErrorMessage>
                {errors.applicantFirstName?.message?.toString()}
              </FormErrorMessage>
            </FormControl>
          </Box>
          <Box width="50%">
            <FormControl isInvalid={errors.applicantLastName !== undefined} isRequired>
              <FormLabel htmlFor='applicantLastName'>Last Name</FormLabel>
              <Input
                id='applicantLastName'
                placeholder='Doe'
                {...register('applicantLastName', {
                  required: true,
                })}
              />
              <FormErrorMessage>
                {errors.applicantLastName?.message?.toString()}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </HStack>
      }
      {
        applicantType === 'Organization' && 
        <Box>
          <FormControl isInvalid={errors.applicantCorporationName !== undefined} isRequired>
            <FormLabel htmlFor='applicantCorporationName'>Organization Name</FormLabel>
            <Input
              id='applicantCorporationName'
              placeholder='XYZ Design Inc.'
              {...register('applicantCorporationName', {
                required: true,
              })}
            />
            <FormErrorMessage>
              {errors.applicantCorporationName?.message?.toString()}
            </FormErrorMessage>
          </FormControl>
        </Box>
      }
      <Box>
        <FormControl isInvalid={errors.applicantEmail !== undefined} isRequired>
          <FormLabel htmlFor='applicantEmail'>Email</FormLabel>
          <Input
            id='applicantEmail'
            type='email'
            placeholder='johndoe@xyzdesign.com'
            {...register('applicantEmail', {
              required: true,
            })}
          />
          <FormErrorMessage>
            {errors.applicantEmail?.message?.toString()}
          </FormErrorMessage>
        </FormControl>
      </Box>
      <Box>
        <FormControl isInvalid={errors.applicantStreetAddress !== undefined} isRequired>
          <FormLabel htmlFor='applicantStreetAddress'>Street Address</FormLabel>
          <Input
            id='applicantStreetAddress'
            placeholder='123 Apple Street, Suite 210'
            {...register('applicantStreetAddress', {
              required: true,
            })}
          />
          <FormErrorMessage>
            {errors.applicantStreetAddress?.message?.toString()}
          </FormErrorMessage>
        </FormControl>
      </Box>
      <HStack>
        <Box>
          <FormControl isInvalid={errors.applicantCity !== undefined} isRequired>
            <FormLabel htmlFor='applicantCity'>City</FormLabel>
            <Input
              id='applicantCity'
              placeholder='Springfield'
              {...register('applicantCity', {
                required: true,
              })}
            />
            <FormErrorMessage>
              {errors.applicantCity?.message?.toString()}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <StateInput id="applicantState" countryFormId={"applicantCountry"} register={register} errors={errors} setValue={setValue} watch={watch} />
        <CountryInput id="applicantCountry" register={register} errors={errors} />
      </HStack>
      <Box>
        <FormControl isInvalid={errors.applicantCity !== undefined} isRequired>
          <FormLabel htmlFor='applicantPostalCode'>ZIP/Postal Code</FormLabel>
          <Input
            id='applicantPostalCode'
            placeholder='10282'
            {...register('applicantPostalCode', {
              required: true,
            })}
          />
          <FormErrorMessage>
            {errors.applicantPostalCode?.message?.toString()}
          </FormErrorMessage>
        </FormControl>
      </Box>
    </VStack>
  )
}
