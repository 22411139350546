import { useForm } from 'react-hook-form'
import {
  Button,
  Center,
  Image,
  Container,
  Text,
  VStack,
  Box,
  Flex,
  useToast,
  Spinner
} from '@chakra-ui/react'
import { RequestingFirmFormSection } from '../components/intakeForm/RequestingFirmFormSection'
import { TrademarkApplicationForm } from '../components/intakeForm/TrademarkApplicationForm'
import { ApplicantFormSection } from '../components/intakeForm/ApplicantFormSection'
import { CertificationFormSection } from '../components/intakeForm/CertificationFormSection'
import { GoodsAndServicesFormSection } from '../components/intakeForm/GoodsAndServicesFormSection'
import { AdditionalCommentsSection } from '../components/intakeForm/AdditionalCommentsSection'
import React, {useState, useEffect} from "react"
import { RequestingFirmSummary } from '../components/formReview/RequestingFirmSummary'
import { ApplicantSummary } from '../components/formReview/ApplicantSummary'
import { TrademarkSummary } from '../components/formReview/TrademarkSummary'
import { AdditionalCommentsReview } from '../components/formReview/AdditionalCommentsReview'
import { GoodsAndServicesReview } from '../components/formReview/GoodsAndServicesReview'
import { ContactPersonFormSection } from '../components/intakeForm/ContactPersonSection'
import { ContactPersonSummary } from '../components/formReview/ContactPersonSummary'
import { InternationalFilings } from '../components/intakeForm/InternationalFilings'
import { InternationalFilingsReview } from '../components/formReview/InternationalFilingsReview'
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { useNavigate, useSearchParams } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY!);

export const Home = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const uploadDraftApplicationToast = useToast()
  const [isLoadingFirmInfo, setIsLoadingFirmInfo] = useState(true);
  const [isUploadingToServer, setIsUploadingToServer] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(true);
  const [draftCreated, setDraftCreated] = useState<boolean>(false);
  const [draftTrademarkInfo, setDraftTrademarkInfo] = useState<any>(null);
  const [clientSecret, setClientSecret] = useState('');
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      goodsAndServices: [
        {class: '1', description: ''}
      ]
    }
  })

  async function fetchFirmInfoWithRetries(firmSlug: string): Promise<void> {
    for(let i=0; i<5; i++) {
      try {
        const response = await fetch(`${process.env.REACT_APP_TOBY_BACKEND_API_URL}/firm-info?firm=${firmSlug}`);
        const data = await response.json();
        if (response.ok) {
          setValue('requestingFirmName' as any, data.firmName);
          setValue('requestingFirmStreetAddress' as any, data.streetAddress)
          setValue('requestingFirmCity' as any, data.city)
          setValue('requestingFirmState' as any, data.state)
          setValue('requestingFirmCountry' as any, data.country)
          setValue('requestingFirmPostalCode' as any, data.postalCode)
          setIsLoadingFirmInfo(false)
          return;
        } else if (response.status == 404) {
          setIsLoadingFirmInfo(false);
          return;
        }
      } catch(err) {}
      await new Promise(resolve => setTimeout(resolve, 300));
    }

    setIsLoadingFirmInfo(false);
  }

  useEffect(() => {
    const firmSlug = searchParams.get("firm")
    if (firmSlug) {
      fetchFirmInfoWithRetries(firmSlug)
    } else {
      setIsLoadingFirmInfo(false)
    }
  }, [searchParams])

  function onSubmit(values: any) {
    setIsEditing(false);
    setDraftTrademarkInfo(values);
  }

  function createFormData() : FormData {
    const formValues: any = getValues();
    const formData = new FormData();

    // Requesting Firm Info
    formData.append('requestingFirmName', formValues.requestingFirmName);
    formData.append('requestingFirmStreetAddress', formValues.requestingFirmStreetAddress);
    formData.append('requestingFirmCity', formValues.requestingFirmCity);
    formData.append('requestingFirmState', formValues.requestingFirmState ? formValues.requestingFirmState : ''); 
    formData.append('requestingFirmCountry', formValues.requestingFirmCountry);
    formData.append('requestingFirmPostalCode', formValues.requestingFirmPostalCode);

    // Contact Person
    formData.append('contactPersonName', formValues.contactPersonName);
    formData.append('contactPersonEmail', formValues.contactPersonEmail);

    // Applicant Info
    if (formValues.applicantType === 'Individual') {
      formData.append('applicantName', formValues.applicantFirstName + ' ' + formValues.applicantLastName)
    } else if (formValues.applicantType === 'Organization') {
      formData.append('applicantName', formValues.applicantCorporationName)
    }

    formData.append('applicantEmail', formValues.applicantEmail);
    formData.append('applicantStreetAddress', formValues.applicantStreetAddress);
    formData.append('applicantCity', formValues.applicantCity);
    formData.append('applicantState', formValues.applicantState ? formValues.applicantState : '');
    formData.append('applicantCountry', formValues.applicantCountry);
    formData.append('applicantPostalCode', formValues.applicantPostalCode);

    // Trademark Info
    formData.append('isDesignMark', (formValues.trademarkType === 'Design').toString());
    formData.append('trademark', formValues.trademark);
    formData.append('trademarkImage', formValues.trademarkImage ? formValues.trademarkImage[0] : '')

    // Goods Services
    formData.append('goodsAndServices', JSON.stringify(formValues.goodsAndServices))

    // International filing priority
    formData.append('priority', formValues.priority.toString())
    formData.append('priorityDetails', formValues.priorityDetails)

    // Additional comments
    formData.append('additionalComments', formValues.additionalComments)

    return formData
  }

  async function createDraftApplicationWithRetries(formData: FormData): Promise<void> {
    setIsUploadingToServer(true);
    for(let i=0; i<5; i++) {
      try {
        const response = await fetch(`${process.env.REACT_APP_TOBY_BACKEND_API_URL}/create-draft-trademark-application`, {
          method: "POST",
          body: formData
        });
    
        const data = await response.json();
        if (response.ok) {
          setDraftCreated(true);
          setClientSecret(data.clientSecret);
          setIsUploadingToServer(false);
          return Promise.resolve();
        }
      } catch(_) {}
      await new Promise(resolve => setTimeout(resolve, 300));
    }

    setIsUploadingToServer(false);
    return Promise.reject();
  }

  async function createDraftApplicationAndProceedToCheckout() {
    const formData = createFormData();
    const uploadPromise = createDraftApplicationWithRetries(formData);

    uploadDraftApplicationToast.promise(uploadPromise, {
      success: { title: 'Success' },
      error: { title: 'Sorry, something went wrong', description: 'Please try again!' },
      loading: { title: 'Uploading Trademark Application', description: 'Please wait' },
    })
  }

  return (
    <Container marginTop={5} marginBottom={25} maxW={"105ch"} bg={"white"} padding={5} borderRadius={"lg"}>
      <Container>
        <VStack
          marginBottom={8}
          spacing={4}
          align='stretch'
        >
          <Image src="lomic-law-logo.webp" alt='Lomic Law Logo' />
          <Center><Text fontSize='3xl'>Canadian Trademark Application</Text></Center>
        </VStack>
      </Container>
      {
        isLoadingFirmInfo &&
        <Center><Spinner size="xl" /></Center>
      }
      { (!isLoadingFirmInfo && isEditing && !draftCreated) &&
        <form onSubmit={handleSubmit(onSubmit)}>
          <Text fontSize='2xl'>Requesting Firm</Text>
          <RequestingFirmFormSection register={register} errors={errors} setValue={setValue} watch={watch} />
          <Text fontSize='2xl' marginTop="20px">Contact Person</Text>
          <ContactPersonFormSection register={register} errors={errors} />
          <Text fontSize='2xl' marginTop="20px">Applicant Information</Text>
          <ApplicantFormSection register={register} errors={errors} setValue={setValue} watch={watch} previouslySelectedType={draftTrademarkInfo ? draftTrademarkInfo.applicantType : null} />
          <Text fontSize='2xl' marginTop="20px">Trademark Information</Text>
          <TrademarkApplicationForm setValue={setValue} watch={watch} register={register} errors={errors} previouslySelectedType={draftTrademarkInfo ? draftTrademarkInfo.trademarkType : null} />
          <Text fontSize='2xl' marginTop="20px">Goods And Services</Text>
          <GoodsAndServicesFormSection control={control} register={register} errors={errors} watch={watch} />
          <Text fontSize='2xl' marginTop="20px">Priority for International Filings - Paris Convention</Text>
          <InternationalFilings register={register} errors={errors} watch={watch} setValue={setValue} />
          <Text fontSize='2xl' marginTop="20px">Additional Comments</Text>
          <AdditionalCommentsSection register={register} errors={errors} />
          <CertificationFormSection register={register} errors={errors} />
          {/* TODO sorend: set default color theme to use LL colors */}
          <Button mt={4} colorScheme='teal' type='submit' backgroundColor="#0C3C60" sx={{ _hover: {backgroundColor: "#39729B"} }}>
            Review
          </Button>
        </form>
      }
      {
        (!isLoadingFirmInfo && !isEditing && !draftCreated) &&
        <Box>
          <Text fontSize='2xl'>Requesting Firm</Text>
          <RequestingFirmSummary 
            firmName={draftTrademarkInfo.requestingFirmName} 
            streetAddress={draftTrademarkInfo.requestingFirmStreetAddress} 
            city={draftTrademarkInfo.requestingFirmCity} 
            state={draftTrademarkInfo.requestingFirmState}
            country={draftTrademarkInfo.requestingFirmCountry} 
            postalCode={draftTrademarkInfo.requestingFirmPostalCode} 
          />
          <Text fontSize='2xl' marginTop="20px">Contact Person</Text>
          <ContactPersonSummary name={draftTrademarkInfo.contactPersonName} email={draftTrademarkInfo.contactPersonEmail} />
          <Text fontSize='2xl' marginTop="20px">Applicant Information</Text>
          <ApplicantSummary 
            draftTrademarkInfo={draftTrademarkInfo}
          />
          <Text fontSize='2xl' marginTop="20px">Trademark Information</Text>
          <TrademarkSummary
            type={draftTrademarkInfo.trademarkType}
            wordmark={draftTrademarkInfo.trademark} 
            figurativeMark={draftTrademarkInfo.trademarkImage ? draftTrademarkInfo.trademarkImage[0] : null}
          />
          <Text fontSize='2xl' marginTop="20px">Goods And Services</Text>
          <GoodsAndServicesReview goodsServies={draftTrademarkInfo.goodsAndServices} />
          {
            draftTrademarkInfo.priority &&
            <>
              <Text fontSize='2xl' marginTop="20px">Priority for International Filings - Paris Convention</Text>
              <InternationalFilingsReview priority={draftTrademarkInfo.priority} priorityDetails={draftTrademarkInfo.priorityDetails} />
            </>
          }
          { draftTrademarkInfo.additionalComments &&
            <>
              <Text fontSize='2xl' marginTop="20px">Additional Comments</Text>
              <AdditionalCommentsReview comments={draftTrademarkInfo.additionalComments} />
            </>
          }
          <Flex marginTop={4} width="20%">
            <Button colorScheme='gray' type='submit' flex={1} onClick={() => { setIsEditing(true) }}>
              Back
            </Button>
            {/* Make sure you handle the case where the server returns an error */}
            <Button colorScheme='teal' isLoading={isUploadingToServer} isDisabled={isUploadingToServer} type='submit' flex={1} marginLeft={4} backgroundColor="#0C3C60" sx={{ _hover: {backgroundColor: "#39729B"} }} onClick={createDraftApplicationAndProceedToCheckout}>
              Continue
            </Button>
          </Flex>
        </Box>
      }
      {
        (!isLoadingFirmInfo && draftCreated && clientSecret) &&
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{clientSecret}}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
      }
    </Container>
  )
}
