import { useFieldArray } from 'react-hook-form'
import {
  FormErrorMessage,
  FormControl,
  Input,
  Button,
  VStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Textarea,
  Tooltip
} from '@chakra-ui/react'
import { DeleteIcon, AddIcon } from '@chakra-ui/icons'

// Classes: 1-34, Services: 35-45

export const GoodsAndServicesFormSection = ({control, register, errors, watch}: {control: any, register: any, errors: any, watch: any}) => {
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "goodsAndServices"
  });
  const goodsAndServices = watch("goodsAndServices")

  const removeGoodOrSerivce = (index: number) => {
    if (fields.length > 1) {
      remove(index)
    }
  }

  const validateNiceClass = (niceClass: number) => {
    let occurencesOfNiceClass = 0;
    for (let goodOrService of goodsAndServices) {
      if (goodOrService['class'] === niceClass) {
        occurencesOfNiceClass += 1;
      }
    }
    if (occurencesOfNiceClass > 1) {
      return `Duplicate Nice Class: ${niceClass}`
    }
    return true
  }

  const getClassErrorMessage = (errors: any, index: number) => {
    if (errors["goodsAndServices"] && errors["goodsAndServices"][index] && errors["goodsAndServices"][index]["class"]) {
      console.log(errors["goodsAndServices"][index]["class"].message.toString())
      return errors["goodsAndServices"][index]["class"].message.toString()
    }
  }

  return (
    <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="1px">
      <TableContainer>
        <Table variant='simple' size={"sm"}>
          <Thead>
            <Tr>
              <Th sx={{ width: "10%" }}>Nice Class</Th>
              <Th sx={{ width: "85%" }}>Description</Th>
              <Th sx={{ width: "5%" }}></Th>
            </Tr>
          </Thead>
          <Tbody>
            {fields.map((field: any, index: any) => (
              <Tr key={`goodsAndServices.${index}`}>
                <Td>
                  <FormControl isInvalid={errors.goodsAndServices && errors.goodsAndServices[index] && errors.goodsAndServices[index]["class"] !== undefined} isRequired>
                    <NumberInput defaultValue={1} min={1} max={45}>
                      <NumberInputField {...register(`goodsAndServices.${index}.class` as const, {
                        required: true,
                        validate: validateNiceClass
                      })} />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <FormErrorMessage>
                      {getClassErrorMessage(errors, index)}
                    </FormErrorMessage>
                  </FormControl>
                </Td>
                <Td>
                  <FormControl isInvalid={errors.goodsAndServices && errors.goodsAndServices[index] && errors.goodsAndServices[index]["description"] !== undefined} isRequired>
                    <Textarea id={`goodsAndServices.${index}.description`} placeholder='Description' {...register(`goodsAndServices.${index}.description` as const, {
                      required: true,
                    })}/>
                  </FormControl>
                </Td>
                <Td><Button onClick={() => removeGoodOrSerivce(index)}><DeleteIcon/></Button></Td>
              </Tr>
            ))}
          </Tbody>
          <TableCaption padding={0}><Tooltip label="Additional Nice Class"><Button onClick={() => append({ 'class': 1, 'description': '' })}><AddIcon/></Button></Tooltip></TableCaption>
        </Table>
      </TableContainer>
    </VStack>
  )
}
