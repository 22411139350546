import {
  Center,
  Image,
  Container,
  Text,
  VStack,
  HStack,
  Box,
  UnorderedList,
  ListItem,
  Spinner,
  Link,
  Button
} from '@chakra-ui/react'
import React, {useState, useEffect} from "react"
import { useNavigate, useSearchParams } from "react-router-dom";
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'

export const CheckoutResult = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [checkoutStatus, setCheckoutStatus] = useState(null);
  const [timedOutOrServerFailure, setTimedOutOrServerFailure] = useState(false);
  const navigate = useNavigate();
  

  async function fetchSessionWithRetries(sessionId: string): Promise<void> {
    for(let i=0; i<5; i++) {
      try {
        const response = await fetch(`${process.env.REACT_APP_TOBY_BACKEND_API_URL}/session-status?session_id=${sessionId}`);
        const data = await response.json();
        if (response.ok) {
          setCheckoutStatus(data.status);
          return;
        }
      } catch(err) {}
      await new Promise(resolve => setTimeout(resolve, 300));
    }

    setTimedOutOrServerFailure(true);
  }

  useEffect(() => {
    const sessionId = searchParams.get("session_id")
    if (sessionId) {
      fetchSessionWithRetries(sessionId)
    }
  }, [searchParams])

  return (
    <Container marginTop={5} marginBottom={25} maxW={"105ch"} bg={"white"} padding={5} borderRadius={"lg"}>
      <Container>
        <VStack
          marginBottom={8}
          spacing={4}
          align='stretch'
        >
          <Image src="lomic-law-logo.webp" alt='Lomic Law Logo' />
          <Center><Text fontSize='3xl'>Canadian Trademark Application</Text></Center>
        </VStack>
      </Container>
      {
        (!checkoutStatus && !timedOutOrServerFailure) &&
        <Center><Spinner size="xl" /></Center>
      }
      {
        checkoutStatus === 'complete' &&
        <Box>
          <HStack alignContent={"center"}>
            <Box
              borderRadius="full" // Makes the box circular
              bg="green.500" // Solid red background
              color="white" // White icon color
              display="flex" // Use Flexbox for centering
              alignItems="center" // Center vertically
              justifyContent="center" // Center horizontally
              width={8} // Width of the circle
              height={8} // Height of the circle
            >
              <CheckIcon />
            </Box>
            <Text fontSize='3xl'>Success </Text>
          </HStack>
          <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
            <Text>A hold on your payment was processed succesfully. The Lomic Law team will begin work on your trademark application immediately.</Text>
          </VStack>
          <Text fontSize='3xl' marginTop="20px">Next Steps </Text>
          <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
            <UnorderedList>
              <ListItem>No further action is required on your part.</ListItem>
              <ListItem>In a few mintues, you will receive an email with a summary of your draft trademark application and a receipt.</ListItem>
              <ListItem>If you have any amendments to your draft trademark application please let us know as soon as possible at {<Link href={`mailto:info@lomic-law.ca`}>info@lomic-law.ca</Link>}.</ListItem>
              <ListItem>In 3-5 business days, Lomic Law will send you an email confirming that your trademark application has been filed.</ListItem>
              <ListItem>The hold on your payment will only be charged after your trademark application has been filed.</ListItem>
            </UnorderedList>
          </VStack>
          <Center mt="20px">
            <Box>
              <Button colorScheme='teal' flex={1} backgroundColor="#0C3C60" sx={{ _hover: {backgroundColor: "#39729B"} }} onClick={() => navigate("/")}>
                Submit New Trademark Application
              </Button>
            </Box>
          </Center>
        </Box>
      }
      {
        (checkoutStatus === 'open' || checkoutStatus === 'expired') && 
        <Box>
          <HStack alignContent={"center"}>
          <Box
            borderRadius="full" // Makes the box circular
            bg="red.500" // Solid red background
            color="white" // White icon color
            display="flex" // Use Flexbox for centering
            alignItems="center" // Center vertically
            justifyContent="center" // Center horizontally
            width={8} // Width of the circle
            height={8} // Height of the circle
          >
            <CloseIcon />
          </Box>
            <Text fontSize='3xl'>Oops, something went wrong!</Text>
          </HStack>
          <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
            <Text>There was an issue processing your payment, please try again.</Text>
          </VStack>
          <Text fontSize='3xl' marginTop="20px">What Happened?</Text>
          <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
            <UnorderedList>
              <ListItem>There may have been an issue with your payment method, consider trying a different method.</ListItem>
              <ListItem>Our service may have encountered an unexpected error.</ListItem>
              <ListItem>Further questions? Contact {<Link href={`mailto:info@lomic-law.ca`}>info@lomic-law.ca</Link>}</ListItem>
            </UnorderedList>
          </VStack>
        </Box>
      }
      {
        timedOutOrServerFailure && 
        <Box>
          <HStack alignContent={"center"}>
          <Box
            borderRadius="full" // Makes the box circular
            bg="yellow.500" // Solid red background
            color="white" // White icon color
            display="flex" // Use Flexbox for centering
            alignItems="center" // Center vertically
            justifyContent="center" // Center horizontally
            width={8} // Width of the circle
            height={8} // Height of the circle
          >
          </Box>
          <Text fontSize='3xl'>We're unable to confirm the hold on your payment</Text>
          </HStack>
          <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
            <Text>This does not mean that anything has gone wrong. You will not be charged until Lomic Law actually files your trademark application.</Text>
          </VStack>
          <Text fontSize='3xl' marginTop="20px">What can I do?</Text>
          <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
            <UnorderedList>
              <ListItem>Refresh the page to check your status again (you will not be double-charged).</ListItem>
              <ListItem>If refreshing the page does not work please contact {<Link href={`mailto:info@lomic-law.ca`}>info@lomic-law.ca</Link>}.</ListItem>
            </UnorderedList>
          </VStack>
        </Box>
      }
    </Container>
  )
}
