import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Image,
  VStack,
  Box,
  IconButton,
  Radio,
  RadioGroup
} from '@chakra-ui/react'
import { AttachmentIcon, CloseIcon } from '@chakra-ui/icons'

// Classes: 1-34, Services: 35-45

export const TrademarkApplicationForm = ({setValue, watch, register, errors, previouslySelectedType}: {setValue: any, watch: any, register: any, errors: any, previouslySelectedType: string | null}) => {
  const trademarkType = watch('trademarkType')
  const trademarkImage = watch('trademarkImage')

  // This styling renders a transpart file input behind the "Upload Image" button so that we can use custom styling, but still render the file input on the screen for browser form validation
  const hiddenFileInputStyle = {
    opacity: 0,
    position: 'absolute',
    zIndex: -1,
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    cursor: 'pointer'
  };

  return (
    <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
      <Box>
        <FormLabel htmlFor='trademarkType'>Trademark Type</FormLabel>
        <RadioGroup defaultValue={previouslySelectedType ?? 'Standard Character'}>
          <VStack align="left">
            <Radio value='Standard Character' {...register('trademarkType')}>Standard Character</Radio>
            <Radio value='Design' {...register('trademarkType')}>Design</Radio>
          </VStack>
        </RadioGroup>
      </Box>
      {
        (!trademarkType || trademarkType === 'Standard Character') &&
        <Box>
          <FormControl isInvalid={errors.trademark !== undefined} isRequired>
            <FormLabel htmlFor='trademark'>Trademark (Standard Character)</FormLabel>
            <Input
              id='trademark'
              placeholder=''
              {...register('trademark', {
                required: true,
              })}
            />
            <FormErrorMessage>
              {errors.trademark?.message?.toString()}
            </FormErrorMessage>
          </FormControl>
        </Box>
      }
      {
        trademarkType === 'Design' &&
        <Box>
          <FormControl isInvalid={errors.trademarkImage !== undefined} isRequired={!trademarkImage}>
            <FormLabel htmlFor='trademarkImage'>Trademark Image</FormLabel>
            <label style={{ position: 'relative', cursor: "pointer" }}>
              <Input 
                id='trademarkImage'
                type='file'
                accept="image/*"
                style={hiddenFileInputStyle}
                {...register('trademarkImage')}
              />
              <Button as="span">Upload Image <AttachmentIcon marginLeft={"3px"} /></Button>
            </label>
            <FormErrorMessage>
              {errors.trademarkImage?.message?.toString()}
            </FormErrorMessage>
          </FormControl>
          {
            (trademarkImage && trademarkImage.length > 0) && 
              <Box display={"flex"} marginTop={"20px"}>
                <Image
                  boxSize='120px'
                  objectFit='cover'
                  src={URL.createObjectURL(watch('trademarkImage')[0])}
                  alt='Trademark Image'
                />
                <IconButton
                  onClick={() => setValue('trademarkImage', null)}
                  isRound={true}
                  variant='solid'
                  colorScheme='red'
                  aria-label='Remove'
                  size='sm'
                  left='-15px'
                  top = '-10px'
                  icon={<CloseIcon />}
                />
              </Box>
          }
        </Box>
      }
    </VStack>
  )
}
