import * as React from "react"
import {
  ChakraProvider,
  theme,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { NotFound } from "./pages/NotFound";
import { CheckoutResult } from "./pages/CheckoutResult";

export const App = () => (
  <ChakraProvider theme={theme}>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/checkout-result" element={<CheckoutResult />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </ChakraProvider>
)
