import {
  Image,
  Text,
  VStack,
  Box
} from '@chakra-ui/react'

export const TrademarkSummary = ({type, wordmark, figurativeMark}: {type: string, wordmark: string | null, figurativeMark: Blob | MediaSource }) => {
  return (
    <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
      <Box>
        <Text fontWeight="bold">Trademark Type</Text>
        <Text>{type}</Text>
      </Box>
      <Box>
        <Text fontWeight="bold">Trademark</Text>
        {
          type === 'Standard Character' &&
          <Text>{wordmark}</Text>
        }
        {
          type === 'Design' &&
          <Image
            boxSize='100px'
            objectFit='cover'
            src={URL.createObjectURL(figurativeMark)}
            alt='Trademark Image'
          />
        }
      </Box>
    </VStack>
  )
}
