import {
  Text,
  VStack,
  Box
} from '@chakra-ui/react'

export const RequestingFirmSummary = ({firmName, streetAddress, city, state, country, postalCode}: {firmName: string, streetAddress: string, city: string, state: string | undefined, country: string, postalCode: string}) => {
  return (
    <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
      <Box>
        <Text fontWeight="bold">Firm Name</Text>
        <Text>{firmName}</Text>
      </Box>
      <Box>
        <Text fontWeight="bold">Address</Text>
        <Text>{`${streetAddress}, ${city},${state ? ` ${state}` : ""} ${postalCode}, ${country}`}</Text>
      </Box>
    </VStack>
  )
}
