import {
  Text,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react'

// Classes: 1-34, Services: 35-45

export const GoodsAndServicesReview = ({goodsServies}: {goodsServies: any}) => {
  return (
    <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="1px">
      <TableContainer>
        <Table variant='simple' size={"sm"}>
          <Thead>
            <Tr>
              <Th sx={{ width: "10%" }}>Nice Class</Th>
              <Th sx={{ width: "10%" }}>Type</Th>
              <Th sx={{ width: "80%" }}>Description</Th>
            </Tr>
          </Thead>
          <Tbody>
            {goodsServies.map((field: any, index: any) => (
              <Tr key={`goodsAndServicesSummary.${index}`}>
                <Td>
                  <Text>{field.class}</Text>
                </Td>
                <Td>
                  <Text>{field.class <= 34 ? 'Good' : 'Service'}</Text>
                </Td>
                <Td><Text whiteSpace="pre-wrap">{field.description}</Text></Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  )
}
