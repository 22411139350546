import {
  FormControl,
  VStack,
  Box,
  Checkbox,
  Textarea,
  FormLabel
} from '@chakra-ui/react'
import {useEffect} from 'react'

export const InternationalFilings = ({register, errors, watch, setValue}: {register: any, errors: any, watch: any, setValue: any}) => {
  const priorityInput = watch('priority');

  useEffect(() => {
    if (!priorityInput) {
      setValue('priorityDetails', undefined)
    }
  }, [priorityInput])
  return (
    <VStack borderWidth='1px' borderRadius='lg' padding="7px" align="left" spacing="6px">
      <FormControl isInvalid={errors.priority !== undefined}>
        <Checkbox {...register('priority')}>This is a convention priority application.</Checkbox>
      </FormControl>
      {
        priorityInput &&
        <FormControl isInvalid={errors.priorityDetails !== undefined} isRequired>
          <FormLabel htmlFor='priorityDetails'>Priority Details</FormLabel>
          <Textarea 
            id='priorityDetails'
            placeholder='An application for this trademark was filed in the United States on January 17, 2024...'
            {...register('priorityDetails')}
          />
        </FormControl>
      }
    </VStack>
  )
}
